/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.53);
}

/* You can remove below css when using default top bar */
/* Start version 2 CSS */
.layout-topbar-v2 {
    .p-menubar {
        background: transparent !important;
        border: none !important;
    }

    .p-menubar > a > i {
        font-size: 1.5rem !important;
    }

    .p-menubar .p-menubar-root-list {
        width: 300px !important;
    }
}

.layout-main-container-v2 {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 7rem 2rem 2rem 2rem;
    transition: margin-left $transitionDuration;
}
.layout-topbar .layout-menu-button {
    margin-left: 0rem;
}
.p-dialog {
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    max-height: 90%;
    transform: unset !important;
    position: relative;
    width: 50% !important;
}
.layout-menu ul a {
    font-size: 17px !important;
}
.pi {
    font-size: 16px;
    margin-top: 3px;
}
.action-btn {
    .p-button.p-button-icon-only {
        width: 20px;
        margin-right: 10px;
    }
}
.record-delet-modal {
    .p-dialog {
        width: 30% !important;
    }
}

.script-table {
    td {
        padding: 3px 12px !important;
        &:last-child {
            text-align: center !important;
        }
    }
    th {
        &:last-child {
            text-align: center !important;
        }
    }
}
.script-table1 {
    td {
        padding: 3px 12px !important;
        height: 48px !important;
    }
}
.system-table{
    td{
        padding: 3px 4px !important;
    }
}

/* End version 2 CSS */

.p-datatable-thead {
    width: 100%;
}

// .p-datatable-tbody{
//     overflow-y: scroll;
//       display: inherit !important;
//       max-height: 600px;
// }
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: #999;
    border-radius: 20px;
  }
  .btnwidth{
    .p-selectbutton.p-buttonset.p-component{
        display: flex !important;
        justify-content: space-between !important;
        --bs-gutter-x: 0;
        div{
            width: 33.3%;
        }
    }
}

